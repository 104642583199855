import $ from "jquery";

$(document).on("click", "#fliptoBack", function () {
  $(".login-card").addClass("flipped");
});
$(document).on("click", "#gotoNewData", function () {
  $("#form-login").hide();
  $("#loginForm").show();
});

$(document).on("click", function () {
  $("#fliptoregister").click(function () {
    $(".login-card").addClass("flipped");
  });

  $("#fliptoFront").click(function () {
    $(".login-card").removeClass("flipped");
  });

  $("#login-back").click(function () {
    $("#loginForm").hide();
    $("#form-login").show();
  });

  $("#academicsBtn").click(function () {
    $(".reg").css("display", "none");
    $(".counselling").hide();

    $(".back").css("background-color", "white");
    $(".counseller").show();
  });

  $("#counsellingBtn").click(function () {
    $(".reg").hide();
    $(".counseller").hide();
    $(".counselling").show();
    $(".back").css("background-color", "white");
  });

  $("#goToAcad").click(function () {
    $(".counselling").hide();
    $(".counseller").show();
  });
  $("#goToCouseling").click(function () {
    $(".counseller").hide();
    $(".counselling").show();
  });

  $("#regAsbacktoLogin").click(function () {
    $(".login-card").removeClass("flipped");
    $(".reg").show();
    $(".counseller").hide();
    $(".back").css("background-color", "rgb(142, 68, 173)");
  });

  $("#counsToLogin").click(function () {
    $(".login-card").removeClass("flipped");
    $(".reg").show();
    $(".back").css("background-color", "rgb(142, 68, 173)");
    $(".counselling").hide();
  });

  /*  current lp page start */

  $("#measurementdata").click(function () {
    $(".firsttabcontent").hide();
    $(".secondtabcontent").show();
  });
  $("#geometrydata").click(function () {
    $(".secondtabcontent").hide();
    $(".firsttabcontent").show();
  });

  /*  current lp page end */

  // Relationship User Status Modal

  $("#modalassociateBtn").click(function () {
    $(".modalBodyWrap").hide();
    $(".newDataAppear").show();
    $("#relationModalBackArrow").show();
    $(".relationModalTitleWrap").hide();
    $(".relationModalTitleWrapNewData").show();
    $(".relationBtnWrap").hide();
    $(".relationNewBtnWrap").show();
  });
  $(".relationModalBackArrow").click(function () {
    $(".relationModalTitleWrapNewData").hide();
    $(".newDataAppear").hide();
    $(".relationModalTitleWrap").show();
    $(".modalBodyWrap").show();
    $(".relationModalBackArrow").hide();
    $(".relationModalTitleWrapSecondNewData").hide();
    $(".secondNewDataAppear").hide();
    $(".relationBtnWrap").show();
    $(".relationNewBtnWrap").hide();
    $(".relationNewBtnWrapRejection").hide();
  });
  $(".modalRejectBtn").click(function () {
    $(".relationModalTitleWrap").hide();
    $(".modalBodyWrap").hide();
    $(".relationModalBackArrow").show();
    $(".relationModalTitleWrapSecondNewData").show();
    $(".secondNewDataAppear").show();
    $(".relationModalTitleWrapNewData").hide();
    $(".newDataAppear").hide();
    $(".relationBtnWrap").hide();
    $(".relationNewBtnWrapRejection").show();
  });
  $("#unAssignLpInput").on('input', function () {
    if ($(this).val().trim() === "") {
      $(".token-input-list").hide();
    } else {
      $(".token-input-list").show();
    }
  })
});

$(document).ready(function () {
  // Handle click on parent <li> element with child <a>
  $(document).on('click', 'li > a', function () {
      // Toggle the immediate next sibling <ul> (child menu)
      $(this).next('ul').toggleClass("show");

      // Find the <span> within the clicked <a> to toggle the dropdown arrow rotation
      $(this).find('span').toggleClass("rotate");
  });

  // // Handle click on first-level <li> items in the <ul> (inner child <li>)
  // $(document).on('click', 'nav ul > li', function () {
  //     // Add active class to the clicked <li> and remove it from its siblings
  //     $(this).addClass("active").siblings().removeClass("active");

  //     // Remove active state from any inner <li> of a different submenu
  //     $(this).siblings().find('li').removeClass("submenuLiIsActive");
  // });

  // // Handle click on second-level <li> items inside <ul> (inner child targeting)
  // $(document).on('click', 'nav ul li ul li', function () {
  //     // Add submenu active state to the clicked inner <li> and remove it from its siblings
  //     $(this).addClass("submenuLiIsActive").siblings().removeClass("submenuLiIsActive");

  //     // Remove the active state from other inner <li> items in different branches
  //     $(this).parents('li').siblings().find('ul li').removeClass("submenuLiIsActive");
  // });

  $(document).on('click', 'nav ul li', function(){
    $(this).addClass("active").siblings().removeClass("active");
    $("nav ul .innerLI").removeClass("submenuLiIsActive")
  });
  $(document).on('click', 'nav ul .innerLI', function(){
    $(this).addClass("submenuLiIsActive").siblings().removeClass("submenuLiIsActive");
    $(this).removeClass("active")
  });
});
