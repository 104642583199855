import { createSlice } from "@reduxjs/toolkit";

const getIndexSlices = createSlice({
    name: "userIndex",
    initialState: { index: -1 },
    reducers: {
        updateIndex: (state, action) => {
            state.index = action.payload;
        },
    },
});
export default getIndexSlices.reducer;
export const { updateIndex } = getIndexSlices.actions;
