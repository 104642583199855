import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import "./assets/css/style.css";
import "./assets/js/custom";
import "react-loading-skeleton/dist/skeleton.css";
import "react-responsive-pagination/themes/classic.css";
import "./index.css";
import 'bootstrap/dist/js/bootstrap.min.js';
import Loader from "./components/loader";
import { Provider } from "react-redux";
import store from "./store";
import Routes from "./Routes";
const router = createBrowserRouter(Routes);
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <RouterProvider router={router} fallbackElement={<Loader />} />
  </Provider>
);
