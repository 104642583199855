import "../assets/css/style.css";
import "../assets/js/custom";
import { Link, useRouteError, useNavigate } from "react-router-dom";
import persistedState from "../persited_store.js";

const ErrorPage = () => {
  let error = useRouteError();
  const navigate = useNavigate();
  return (
    <>
      {error.status == 404 ? (
        <div className="login">
          <div className="loginInner">
            <div className="goBackBtn">
              <button onClick={() => navigate(-1)}>Go Back</button>
            </div>
          </div>
        </div>
      ) : (
        <div className="wrap">
          <div className="errorserver-block">
            <h2>Oops! Internal server error</h2>
            <h1>500</h1>
            <div className="error-image">
              {/* You can place an image here if needed */}
            </div>
            <p>
              Don't Worry, an email with error details has already been sent to
              the administrator.
            </p>
            <p>
              Please try again later or contact the administrator @{" "}
              <b> +1 (888) 295-3916</b>{" "}
            </p>
            <div className="back-button-five">
              {persistedState.token() ? (
                <Link to="/account">Go to Home Page</Link>
              ) : (
                <Link to="/login">Go to Home Page</Link>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ErrorPage;
