const persistedStore = {
  user: () => JSON.parse(localStorage.getItem("user")),
  celverUser: () => JSON.parse(localStorage.getItem("clever_user")),
  token: () => localStorage.getItem("token") || null,
  setToken: (value) => localStorage.setItem("token", value),
  lang: () => localStorage.getItem("lang") || "EN",
  setLang: (value) => localStorage.setItem("lang", value),
  classRoom: () => JSON.parse(localStorage.getItem("classRoom")) || {},
  setClassRoom: (value) =>
    localStorage.setItem("classRoom", JSON.stringify(value)),
  userClassRoom: () => JSON.parse(localStorage.getItem("userClassroom")) || {},
  setUserClassRoom: (value) =>
    localStorage.setItem("userClassroom", JSON.stringify(value)),
};

export default persistedStore;
