import { createSlice } from "@reduxjs/toolkit";

const getPageSlices = createSlice({
    name: "pageIndex",
    initialState: { index: 0 },
    reducers: {
        updateIndex: (state, action) => {
            state.index = action.payload;
        },
    },
});
export default getPageSlices.reducer;
export const { updateIndex } = getPageSlices.actions;
