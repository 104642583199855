import { createSlice } from "@reduxjs/toolkit";
const moveSlice = createSlice({
    name: "moveRequests",
    initialState: {
        value: 0
    },
    reducers: {
        updateReqCount: (state, action) => {
            state.value = action.payload;
        },
    },
});
export default moveSlice.reducer;
export const { updateReqCount } = moveSlice.actions;
