import { configureStore } from "@reduxjs/toolkit";
import langSlice from "./slices/langSlice";
import userStatusSlice from "./slices/userStatusSlice";
import moveRequestSlice from "./slices/moveRequestSlice";
import getIndexSlice from "./slices/getIndexSlice";
import getPageSlice from "./slices/getPageSlice";

let store = configureStore({
    reducer: {
        language: langSlice,
        userStatus: userStatusSlice,
        moveReqCounts: moveRequestSlice,
        userIndex: getIndexSlice,
        pageIndex: getPageSlice
    },
});
export default store;
