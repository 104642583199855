import { createSlice } from "@reduxjs/toolkit";
import persistedState from "../../persited_store.js";

const langSlice = createSlice({
  name: "language",
  initialState: { value: persistedState.lang() },
  reducers: {
    updateLanguage: (state, action) => {
      state.value = action.payload;
      persistedState.setLang(action.payload);
    },
  },
});
export default langSlice.reducer;
export const { updateLanguage } = langSlice.actions;
