import { createSlice } from "@reduxjs/toolkit";
const statusSlice = createSlice({
    name: "userStatus",
    initialState: {
        student: 0,
        teacher: 0,
        parent: 0,
        relationship: 0,
        deleteRequest: 0,
    },
    reducers: {
        updateStatus: (state, action) => {
            state.student = action.payload.student;
            state.teacher = action.payload.teacher;
            state.parent = action.payload.parent;
            state.relationship = action.payload.relationship;
            state.deleteRequest = action.payload.deletionRequestCount;
        },
    },
});
export default statusSlice.reducer;
export const { updateStatus } = statusSlice.actions;
