import React from "react";
function Loader() {
  return (
    <div id="loader-wrapper">
      <div className="loader-inner-wrapper">
        <div id="loader-left">
          <span className="anim"></span>
        </div>
        <div id="loader-right">
          <span className="anim"></span>
        </div>
        <div className="loader-logo"> </div>
      </div>
    </div>
  );
}

export default Loader;
