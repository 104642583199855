/* eslint-disable import/no-anonymous-default-export */
import config from '../config.json';
import persistedState from "../persited_store";
import _ from "lodash";
import moment from "moment-timezone"

export default {
  isAdmin: () => _.toLower(persistedState.user()?.role) === "superadmin",
  isTeacher: () => _.toLower(persistedState.user()?.role) === "teacher",
  isDistrictAdmin: () => _.toLower(persistedState.user()?.role) === "admin",
  isSchoolAdmin: () => _.toLower(persistedState.user()?.role) === "schooladmin",
  isStudent: () => _.toLower(persistedState.user()?.role) === "student",
  isParent: () => _.toLower(persistedState.user()?.role) === "parent",
  isCoordinator: () => _.toLower(persistedState.user()?.role) === "coordinator",
  getSubDomain: () => {
    // return "global";
    const full = window.location.host
    //window.location.host is subdomain.domain.com
    const parts = full.split('.')
    return parts[1] == "com" || parts[0] == "www" ? "global" : parts[0]

  },
  getSubDomainUrl: () => {
    // return config.API_URL
    return `${window.location.origin}/api/`;
  },
  getLocalTime: (utcTime) => {
    return moment.utc(utcTime, "MM/DD/YYYY HH:mm:ss").local().format("MM/DD/YYYY HH:mm:ss");
  }
};
